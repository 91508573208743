$available-local-color: #2c8f41;
$occupied-local-color: #d25e5e;
$subscription_with_benefit: #8a0970;
$construction: #9099a1;
$preparation: #3d91ff;

.local-selection-modal {
  &__map {
    padding: 5px;
    margin-top: 0;
    width: 100%;
    height: calc(100% + 55px);

    @include screen-sm-max {
      max-height: 100%;
    }

    @include screen-md-min {
      margin-top: -55px;
    }

    &__wrapper {
      transition: height 100ms linear;
      position: relative;
    }

    &__legend {
      position: absolute;
      right: 10px;
      top: -45px;
      max-width: 30px;
      max-height: 30px;
      background-color: #3f3f3f;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e6e7e8;
      transition: max-width 200ms linear, max-height 200ms linear, padding 200ms linear;
      overflow: hidden;
      padding: 5px;

      @include screen-sm-max {
        top: 10px;
      }

      &__close {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        transition: top 200ms linear, right 200ms linear, opacity 200ms linear;
        font-size: 20px;
      }

      &__icon {
        position: relative;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 48%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: transparent;
          transition: width 200ms linear, height 200ms linear;
        }

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 48%;
          transform: translate(-50%, -50%);
          width: 0;
          height: 0;
          border-radius: 50%;
          background-color: transparent;
          transition: width 200ms linear, height 200ms linear;
        }
      }

      &__variant {
        width: 25px;
        height: 15px;
        border-radius: 2px;

        &__container {
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          transition: max-width 200ms linear, opacity 200ms linear;
        }

        &.is-available {
          background-color: $available-local-color;
        }

        &.is-occupied {
          background-color: $occupied-local-color;
        }

        &.is-subscription_with_benefit {
          background-color: $subscription_with_benefit;
        }

        &.is-construction {
          background-color: $construction;
        }

        &.is-preparation {
          background-color: $preparation;
        }
      }

      &.is-visible {
        max-width: 400px;
        max-height: 150px;
        justify-content: flex-start;
        padding: 15px 40px 15px 40px;

        .local-selection-modal__map__legend__icon {
          margin-right: 35px;

          &:after {
            width: 30px;
            height: 30px;
            background-color: #ddd0d03d;
          }

          &:before {
            width: 40px;
            height: 40px;
            background-color: rgba(255, 255, 255, 0.41);
          }
        }

        .local-selection-modal__map__legend__close {
          opacity: 1;
          right: 5px;
          top: 5px;
        }

        .local-selection-modal__map__legend__variant__container {
          max-width: 160px;
          opacity: 1;
        }
      }
    }

    &__placeholder {
      margin-top: -55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $darker-gray;
      background-color: $light-blue;
      padding-block: 70px;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.5rem;

      @include screen-sm-max {
        border-top-right-radius: 1.5rem;
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 0;
        height: auto;
        margin-bottom: 20px;
        padding-block: 40px;
      }
    }
  }

  &__zoom-buttons {
    &__wrapper {
      position: absolute;
      bottom: 15px;
      right: 10px;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
    }

    &__spacer {
      width: 70%;
      margin: 0 auto;
    }
  }

  &__apartments-list {
    flex: 1;
    display: block;

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background-color: #e0e0e0;
      border-radius: 20%;
    }

    ::-webkit-scrollbar-thumb {
      background: #a5a5a5;
      border-radius: 20%;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(#a5a5a5, 0.7);
    }

    &__wrapper {
      overflow-y: auto;
    }
  }
}

;@import "sass-embedded-legacy-load-done:129";